import Marketing from "./Marketing";
import Preference from "./Preference";
import Measurement from "./Measurement";
import SocialMedia from "./SocialMedia";
import Functions from "./Functions";
import { useState } from "react";
// import { MdCheck } from "react-icons/md";
import Link from "next/link";

const CookieSettingModal = ({ setShowModal, acceptCookies, locale }) => {
  const [selected, setSelected] = useState(0);
  return (
    <div className="fixed top-0 start-0 w-screen bg-white min-h-screen max-h-screen overflow-y-auto z-[99999999999]">
      <div className="w-3/4 h-full md:p-10 mx-auto sm:w-11/12 max-sm:my-10">
        <h2 className="text-xl font-semibold">
          {locale === "en" ? "Customize your choice" : "قم بتخصيص اختيارك"}
        </h2>
        <h4 className="mt-3 mb-6 text-base text-black text-opacity-60">
          {locale === "en"
            ? "On this page you can find more information about purposes of data processing and the vendors that we use on our websites."
            : "في هذه الصفحة، يمكنك العثور على مزيد من المعلومات حول أغراض معالجة البيانات والبائعين الذين نتعامل معهم على مواقعنا الإلكترونية."}
        </h4>
        <div className="grid grid-cols-12 gap-5">
          <ul className="flex flex-col md:col-span-3 gap-4 sm:gap-2 sm:text-sm col-span-full">
            <Functions {...{ selected, setSelected, locale }} />
            <Marketing {...{ selected, setSelected, locale }} />
            <Preference {...{ selected, setSelected, locale }} />
            <Measurement {...{ selected, setSelected, locale }} />
            <SocialMedia {...{ selected, setSelected, locale }} />
          </ul>
          <div className="col-span-full md:col-span-9">
            <h2 className="text-lg font-bold">
              {selected === 0
                ? locale === "en"
                  ? "Functions"
                  : "الدوال"
                : selected === 1
                ? locale === "en"
                  ? "Marketing"
                  : "التسويق"
                : selected === 2
                ? locale === "en"
                  ? "Preference"
                  : "التفضيل"
                : selected === 3
                ? locale === "en"
                  ? "Measurements"
                  : "القياسات"
                : locale === "en"
                ? "Social Media"
                : "التواصل الإجتماعى"}
            </h2>
            <p className="mt-2.5 font-semibold text-black text-opacity-60">
              {selected === 0
                ? locale === "en"
                  ? `These cookies and similar technologies are used for activities
              that are strictly necessary to operate or deliver the service you
              requested from us. They do not require you to consent and cannot
              be deactivated.`
                  : `تُستخدم ملفات تعريف الارتباط هذه والتقنيات المماثلة للأنشطة الضرورية تمامًا لتشغيل أو تقديم الخدمة التي طلبتها منا. ولا تتطلب موافقتك ولا يمكن تعطيلها.`
                : selected === 1
                ? locale === "en"
                  ? `These cookies and similar technologies help us to deliver personalized ads or marketing content to you, and to measure their performance.`
                  : `تساعدنا ملفات تعريف الارتباط هذه والتقنيات المماثلة في تقديم إعلانات مخصصة أو محتوى تسويقي لك، وقياس أدائها.`
                : selected === 2
                ? locale === "en"
                  ? `These cookies and similar technologies help us to improve the quality of your user experience, and provide you enhanced functionality and personalisation.`
                  : `تساعدنا ملفات تعريف الارتباط هذه والتقنيات المماثلة على تحسين جودة تجربة المستخدم الخاصة بك، وتزويدك بوظائف وتخصيصات محسنة.`
                : selected === 3
                ? locale === "en"
                  ? `With the help of cookies and similar technologies, we're able to measure how many users visit our website and observe how you interact with our services, which aids us in making improvements.`
                  : `بمساعدة ملفات تعريف الارتباط والتقنيات المماثلة، نتمكن من قياس عدد المستخدمين الذين يزورون موقعنا الإلكتروني ومراقبة كيفية تفاعلك مع خدماتنا، مما يساعدنا في إجراء التحسينات.`
                : locale === "en"
                ? `These cookies and similar technologies enable interactions with external content, networks and platforms.`
                : `تتيح ملفات تعريف الارتباط هذه والتقنيات المماثلة التفاعل مع المحتوى والشبكات والمنصات الخارجية.`}
            </p>
          </div>
        </div>
        <div className="flex items-center justify-center gap-4 mt-5 mb-3 xs:gap-2 max-sm:flex-col">
          <button
            onClick={() => setShowModal(false)}
            className="w-40 p-2 text-sm font-semibold text-white rounded-md xs:w-full bg-primary"
          >
            {locale === "en" ? "Save + Exit" : "حفظ + خروج"}
          </button>
          <button
            onClick={acceptCookies}
            className="flex items-center justify-center w-40 gap-2 p-2 font-semibold text-white rounded-md xs:w-full bg-primary"
          >
            {/* <MdCheck /> */}
            <span className="text-sm font-semibold">
              {locale === "en" ? "Accept All" : "قبول الكل"}
            </span>
          </button>
          <button
            onClick={() => setShowModal(false)}
            className="w-40 p-2 text-sm font-semibold text-white rounded-md xs:w-full bg-primary"
          >
            {locale === "en" ? "Reject All" : "رفض الكل"}
          </button>
        </div>
        <div className="flex items-center justify-center gap-4">
          <Link
            href="/"
            className="text-sm font-semibold text-black text-opacity-60"
          >
            {locale === "en" ? "Privacy Policy" : "سياسة الخصوصية"}
          </Link>
          <span className="text-black text-opacity-60"> | </span>
          <Link
            href="/static/terms"
            className="text-sm font-semibold text-black text-opacity-60"
          >
            {locale === "en" ? "T & C" : "الشروط والأحكام"}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CookieSettingModal;
