import * as React from "react";
const FunctionsIcon = (props) => (
  <svg
    width="512px"
    height="512px"
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="var(--ci-primary-color, currentColor)"
      d="M402.034,206.447l-67.528,65.278-3.046-14.231-.044-.2a63.447,63.447,0,0,0-59.129-49.282l-.329-.014H204.774l18.846-87.688A31.451,31.451,0,0,1,252.549,96h47.637V64h-48l-.659.014A63.452,63.452,0,0,0,192.4,113.3L172.044,208H88v32h77.166l-32.6,151.688A31.451,31.451,0,0,1,103.637,416H56v32h48.329l.329-.014a63.452,63.452,0,0,0,59.13-49.282L197.9,240h73.369a31.453,31.453,0,0,1,28.925,24.3l7.2,33.639L193.531,408h46.042l75.711-73.187,5.07,23.693.043.2a63.449,63.449,0,0,0,59.13,49.282L432,408V376H380.549a31.452,31.452,0,0,1-28.927-24.3L342.4,308.6,448.076,206.447Z"
      className="ci-primary"
    />
  </svg>
);
export default FunctionsIcon;
