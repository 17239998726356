import { useState } from "react";
import CheckIcon from "../icons/CheckIcon";
import CloseIcon from "../icons/CloseIcon";
import MeasurementsIcon from "../icons/MeasurementsIcon";

const Measurement = ({ selected, setSelected, locale }) => {
  const [open, setOpen] = useState(false);
  return (
    <li
      onClick={() => setSelected(3)}
      className={`flex items-center justify-center xs:justify-start gap-2 p-1.5 border ${
        selected === 3 && "border-primary"
      } rounded-md cursor-pointer`}
    >
      <span className="flex flex-col items-center justify-center">
        <span
          className={`flex ${
            open ? "flex-row" : "flex-row-reverse"
          } items-center`}
          onClick={() => setOpen(!open)}
        >
          <hr
            className={`w-10 xs:w-14 border ${
              open ? "border-green-600" : "border-red-600"
            }`}
          />
          {open ? (
            <div className="border-2 border-green-600 rounded-full size-4 flex items-center justify-center">
              <CheckIcon className="size-2 text-green-600" />
            </div>
          ) : (
            <div className="border-2 border-red-600 rounded-full h-4 w-4 flex items-center justify-center">
              <CloseIcon className="size-2 text-red-600" />
            </div>
          )}
        </span>
      </span>
      <span className="flex items-center justify-center gap-1">
        <MeasurementsIcon className="size-4" />
        <span>{locale === "en" ? "Measurement" : "القياسات"}</span>
      </span>
    </li>
  );
};

export default Measurement;
