import CheckIcon from "../icons/CheckIcon";
import FunctionsIcon from "../icons/FunctionsIcon";

const Functions = ({ selected, setSelected, locale }) => {
  return (
    <li
      onClick={() => setSelected(0)}
      className={`flex items-center justify-center xs:justify-start gap-2 p-1.5 border ${
        selected === 0 && "border-primary"
      } rounded-md cursor-pointer`}
    >
      <span className="flex flex-col items-center justify-center">
        <span className="flex items-center">
          <hr className="w-10 border border-green-600 xs:w-14" />
          <div className="border-2 border-green-600 rounded-full size-4 flex items-center justify-center">
            <CheckIcon className="size-2 text-green-600" />
          </div>
                  </span>
      </span>
      <span className="flex items-center justify-center gap-1">
        <FunctionsIcon className="size-4" />
        <span>{locale === "en" ? "Functions" : "الدوال"}</span>
      </span>
    </li>
  );
};

export default Functions;
