const NoPage = (props) => (
  <svg
    width={359}
    height={293}
    viewBox="0 0 359 293"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M156.299 241.762C156.331 239.201 156.234 235.647 155.624 231.475C155.111 227.961 153.461 216.739 147.54 208.256C138.594 195.431 128.453 201.149 118.06 187.893C112.107 180.305 113.782 176.328 106.983 170.261C100.973 164.894 94.5242 162.918 87.6441 161.787C70.2487 158.925 41.8662 161.608 41.8662 161.608C44.2653 189.112 53.512 200.49 61.49 205.792C72.6884 213.242 81.3008 208.679 91.2875 217.592C104.283 229.198 95.7522 242.348 107.87 254.425C107.87 254.425 113.172 259.711 154.347 267.534L156.299 241.762Z"
      fill="#F5F5F5"
    />
    <path
      d="M154.339 267.535C154.339 267.535 122.533 213.405 110.423 202.801C98.3059 192.196 41.8662 161.609 41.8662 161.609"
      stroke="#D6D6D6"
      strokeWidth={0.4657}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M82.7074 184.688L47.1846 186.68"
      stroke="#D6D6D6"
      strokeWidth={0.4657}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M90.0518 189.088L92.4102 162.738"
      stroke="#D6D6D6"
      strokeWidth={0.4657}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M106.203 199.521L75.4541 210.883"
      stroke="#D6D6D6"
      strokeWidth={0.4657}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M119.206 213.03L123.167 193.057"
      stroke="#D6D6D6"
      strokeWidth={0.4657}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M129.136 227.182L99.3467 232.622"
      stroke="#D6D6D6"
      strokeWidth={0.4657}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M136.902 239.111L148.109 209.094"
      stroke="#D6D6D6"
      strokeWidth={0.4657}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M143.97 250.391L109.521 255.465"
      stroke="#D6D6D6"
      strokeWidth={0.4657}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M137.39 246C136.447 243.617 134.991 240.372 132.828 236.753C131.006 233.703 125.2 223.961 116.482 218.382C103.315 209.956 96.126 219.114 81.4549 210.835C73.0541 206.102 73.0866 201.783 64.4743 198.774C56.8704 196.115 50.1529 196.757 43.3704 198.335C26.2027 202.336 1 215.665 1 215.665C13.7274 240.169 26.6174 247.146 36.0186 249.001C49.2095 251.603 55.4309 244.097 68.0607 248.521C84.5046 254.279 81.6501 269.69 97.4597 276.22C97.4597 276.22 104.381 279.075 145.417 270.576L137.39 246Z"
      fill="#F5F5F5"
    />
    <path
      d="M145.433 270.569C145.433 270.569 95.3528 232.704 80.1043 227.531C64.8558 222.359 1.01562 215.666 1.01562 215.666"
      stroke="#D6D6D6"
      strokeWidth={0.4657}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M47.5743 221.391L15.5078 236.802"
      stroke="#D6D6D6"
      strokeWidth={0.4657}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M56.0331 222.644L48.1445 197.4"
      stroke="#D6D6D6"
      strokeWidth={0.4657}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M74.9489 226.115L50.8848 248.366"
      stroke="#D6D6D6"
      strokeWidth={0.4657}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M92.133 233.622L88.1562 213.656"
      stroke="#D6D6D6"
      strokeWidth={0.4657}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M106.714 242.91L81.2676 259.322"
      stroke="#D6D6D6"
      strokeWidth={0.4657}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M118.45 250.961L117.336 218.943"
      stroke="#D6D6D6"
      strokeWidth={0.4657}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M129.298 258.688L99.4033 276.547"
      stroke="#D6D6D6"
      strokeWidth={0.4657}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M253.262 270.974H144.823L155.265 199.27H242.82L253.262 270.974Z"
      fill="#58B18E"
    />
    <path
      d="M333.872 211.917H64.2224C57.3748 211.917 51.8203 206.362 51.8203 199.515V29.6345C51.8203 22.7869 57.3748 17.2324 64.2224 17.2324H333.872C340.72 17.2324 346.274 22.7869 346.274 29.6345V199.506C346.274 206.362 340.72 211.917 333.872 211.917Z"
      fill="#305446"
    />
    <path
      d="M326.723 191.488H71.362C64.5145 191.488 58.96 185.933 58.96 179.085V35.4978C58.96 28.6502 64.5145 23.0957 71.362 23.0957H326.723C333.571 23.0957 339.125 28.6502 339.125 35.4978V179.085C339.125 185.933 333.571 191.488 326.723 191.488Z"
      fill="#F5F5F5"
    />
    <path
      d="M276.245 276.197H121.841V268.739C121.841 262.973 126.517 258.297 132.283 258.297H265.803C271.569 258.297 276.245 262.973 276.245 268.739V276.197Z"
      fill="#305446"
    />
    <path
      d="M68.0938 191.488H324.813"
      stroke="#262626"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M128.64 272.471V268.551C128.64 266.185 130.559 264.266 132.925 264.266H248.724"
      stroke="#262626"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M236.421 217.641L240.528 248.975"
      stroke="#262626"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M196.456 208.346C199.775 208.346 202.466 205.655 202.466 202.336C202.466 199.017 199.775 196.326 196.456 196.326C193.137 196.326 190.446 199.017 190.446 202.336C190.446 205.655 193.137 208.346 196.456 208.346Z"
      fill="white"
    />
    <path
      d="M281.922 34.5215H313.809C319.884 34.5215 324.813 39.4498 324.813 45.5248V111.78"
      stroke="white"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M306.261 55.5121C308.099 51.0798 309.921 47.6561 311.726 45.2326C313.523 42.8091 315.874 40.8735 318.761 39.4341C320.452 38.5476 322.209 37.9214 324.047 37.5311C325.877 37.1488 327.625 36.6609 329.276 36.0754C330.927 35.4898 332.448 34.6847 333.839 33.6763C335.229 32.6678 336.376 31.0739 337.279 28.8943C338.807 25.2184 338.864 22.2175 337.441 19.916C336.018 17.6064 333.92 15.8742 331.147 14.7194C327.162 13.0603 323.966 13.0359 321.542 14.6218C319.119 16.2157 316.939 18.81 315.004 22.4046L300.471 16.3621C302 12.6862 303.992 9.58776 306.44 7.08294C308.888 4.57813 311.661 2.73204 314.752 1.55283C317.842 0.373615 321.176 -0.138729 324.763 0.0320538C328.349 0.202837 332.017 1.06488 335.774 2.62632C343.882 6.00131 349.599 10.3115 352.926 15.5652C356.244 20.8188 356.357 27.1702 353.267 34.6034C352.267 37.0106 351.153 39.0112 349.908 40.6052C348.672 42.2073 347.257 43.5329 345.671 44.5901C344.085 45.6473 342.313 46.5175 340.361 47.2006C338.401 47.8838 336.238 48.5262 333.879 49.128C331.692 49.7217 329.87 50.3479 328.406 51.0148C326.942 51.6816 325.69 52.5112 324.665 53.4871C323.64 54.4711 322.778 55.5852 322.079 56.8377C321.38 58.0901 320.688 59.5458 320.005 61.1967L306.261 55.5121ZM296.771 77.0714L302.902 62.3108L318.11 68.6298L311.978 83.3903L296.771 77.0714Z"
      fill="#305446"
    />
    <path
      d="M217.635 77.6641V65.6117C217.635 61.6593 214.43 58.4551 210.478 58.4551H140.514C136.562 58.4551 133.357 61.6593 133.357 65.6117V77.6641V157.956C133.357 161.909 136.562 165.113 140.514 165.113H252.393C256.345 165.113 259.55 161.909 259.55 157.956V84.8207C259.55 80.8683 256.345 77.6641 252.393 77.6641H217.635Z"
      fill="#F5F5F5"
    />
    <path
      d="M210.551 94.498L196.458 108.592L182.364 94.498L173.857 103.005L187.951 117.098L173.857 131.192L182.364 139.699L196.458 125.605L210.551 139.699L219.058 131.192L204.964 117.098L219.058 103.005L210.551 94.498Z"
      fill="#D6D6D6"
    />
    <path
      d="M43.7381 275.506V266.227H26.6436V291.746L65.2323 292.364V287.721L43.7381 275.506Z"
      fill="#F7A99C"
    />
    <path
      d="M66.5974 292.365V290.161C66.5974 287.909 65.3612 285.843 63.385 284.778L47.1119 275.994C42.2324 277.328 38.467 277.141 35.889 276.702C31.6113 275.978 27.5206 274.035 26.1462 275.661C25.7559 276.125 25.7071 276.735 25.7234 277.157V290.649C25.7234 291.593 26.4878 292.365 27.4393 292.365H66.5974Z"
      fill="#FFC001"
    />
    <path
      d="M66.598 290.893H25.7158"
      stroke="#3897E9"
      strokeWidth={0.733}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M57.588 281.832L55.3027 284.857"
      stroke="#3897E9"
      strokeWidth={0.733}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M55.9122 280.889L53.627 283.914"
      stroke="#3897E9"
      strokeWidth={0.733}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M54.1635 280.012L51.8701 283.037"
      stroke="#3897E9"
      strokeWidth={0.733}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M51.5772 278.408C48.804 279.465 44.2254 280.824 38.4513 280.791C32.8806 280.759 28.4483 279.449 25.7158 278.408"
      stroke="#3897E9"
      strokeWidth={0.733}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M92.4507 275.506V266.227H75.3643V291.746L113.953 292.364V287.721L92.4507 275.506Z"
      fill="#F7A99C"
    />
    <path
      d="M115.318 292.365V290.161C115.318 287.909 114.082 285.843 112.106 284.778L95.8326 275.994C90.9531 277.328 87.1877 277.141 84.6097 276.702C80.332 275.978 76.2413 274.035 74.867 275.661C74.4766 276.125 74.4278 276.735 74.4441 277.157V290.649C74.4441 291.593 75.2085 292.365 76.16 292.365H115.318Z"
      fill="#FFC001"
    />
    <path
      d="M115.319 290.893H74.4365"
      stroke="#3897E9"
      strokeWidth={0.733}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M106.307 281.832L104.014 284.857"
      stroke="#3897E9"
      strokeWidth={0.733}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M104.633 280.889L102.34 283.914"
      stroke="#3897E9"
      strokeWidth={0.733}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M102.876 280.012L100.591 283.037"
      stroke="#3897E9"
      strokeWidth={0.733}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M100.298 278.408C97.5247 279.465 92.9461 280.824 87.172 280.791C81.6013 280.759 77.169 279.449 74.4365 278.408"
      stroke="#3897E9"
      strokeWidth={0.733}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M82.6353 143.344C82.6353 143.344 96.7127 188.04 98.8759 194.229C101.039 200.418 103.048 209.079 102.584 216.659C102.121 224.238 94.8503 270.943 94.8503 270.943H73.6651C73.6651 270.943 72.1687 221.603 71.4449 217.74C70.7211 213.877 64.5404 192.001 64.5404 192.001C64.5404 192.001 62.5317 217.586 60.832 224.385C59.1323 231.191 45.5185 271.708 45.5185 271.708H25.7158L34.0679 143.336H82.6353V143.344Z"
      fill="black"
    />
    <path
      d="M114.929 116.099C114.929 116.099 121.646 110.398 123.346 106.665C124.802 103.477 126.835 97.8659 127.396 96.3126C127.477 96.0767 127.721 95.9466 127.965 96.0035L128.372 96.1011C129.616 96.3939 130.389 97.6463 130.088 98.8906L129.413 101.688L136.512 97.0282C137.122 96.6297 137.944 96.8249 138.302 97.4593C138.619 98.0204 138.464 98.7279 137.952 99.102L133.268 102.518L141.05 98.8662C141.652 98.5815 142.368 98.8092 142.701 99.3867L142.709 99.4029C143.059 100.013 142.848 100.794 142.23 101.143L142.319 101.119C142.994 100.924 143.71 101.249 144.002 101.891C144.344 102.632 143.994 103.502 143.246 103.811L142.75 104.014C143.393 103.811 144.076 104.217 144.206 104.876C144.401 105.844 143.929 106.82 143.059 107.275C140.969 108.365 139.603 108.65 137.618 110.138C136.041 111.317 136.594 112.065 134.918 114.22C132.869 116.839 131.129 116.912 127.607 119.507C126.265 120.491 124.419 122.028 122.468 124.305L114.929 116.099Z"
      fill="#F7A99C"
    />
    <path
      d="M138.424 97.7441C138.424 97.7441 138.782 98.4679 138.107 98.9803C137.432 99.4926 132.138 103.413 132.138 103.413"
      stroke="#262626"
      strokeWidth={0.4147}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M142.237 101.152L134.95 104.535"
      stroke="#262626"
      strokeWidth={0.4147}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M142.986 103.973L135.667 107.34"
      stroke="#262626"
      strokeWidth={0.4147}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M128.885 96.2969C128.885 96.2969 130.162 97.1101 130.129 98.0047C130.097 98.8993 128.804 104.673 128.804 104.673"
      stroke="#262626"
      strokeWidth={0.4147}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M73.6639 270.943C73.6639 270.943 71.7446 220.358 71.5982 219.049C71.4518 217.74 64.2383 190.968 64.2383 190.968L65.5151 179.135C65.5151 179.135 80.0641 214.942 80.0641 217.301C80.0641 219.659 81.6825 270.951 81.6825 270.951H73.6639V270.943Z"
      fill="#3B1C0E"
    />
    <path
      d="M61.6523 221.294L66.8165 165.391"
      stroke="#262626"
      strokeWidth={0.4147}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M95.6634 266.648H73.4941L73.6649 270.942H94.8501L95.6634 266.648Z"
      fill="#262626"
    />
    <path
      d="M46.7953 267.877H25.9679L25.7158 271.716H45.5104L46.7953 267.877Z"
      fill="#262626"
    />
    <path
      d="M96.9561 198.652C99.7618 207.484 100.209 217.048 98.2329 226.1"
      stroke="white"
      strokeWidth={0.4147}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M120.515 110.09C120.515 110.09 116.929 112.855 112.838 113.571C108.048 114.408 103.893 111.578 102.778 110.814C99.745 108.74 98.6227 106.284 96.614 103.405C90.6692 94.8902 86.9689 89.604 81.7966 86.6031C79.121 85.058 74.6562 83.3095 67.6054 83.8462C66.0276 83.2769 63.5066 83.7324 61.815 83.7324C60.4081 83.7324 59.0093 83.7324 57.6024 83.7324C54.8373 84.0658 34.4572 86.4324 30.9928 83.2525C29.6265 82.0001 29.6021 80.764 29.6021 80.764C29.5859 79.7393 30.2283 78.6821 31.9199 77.3971C36.0431 74.258 43.6144 71.9077 46.1518 71.1595L44.6066 59.6113C44.6066 59.6113 44.6066 59.6113 41.2072 59.6113C37.8078 59.6113 25.7392 61.6201 19.2494 63.889C10.141 67.0688 5.26151 69.3947 2.85428 74.6727C-0.162879 81.2845 3.04946 88.3923 3.88711 90.2384C8.06722 99.485 17.3383 103.234 22.9416 105.495C27.878 107.487 32.2777 108.293 35.3355 108.642L32.7087 150.712L81.0647 153.339H88.327C88.88 146.914 86.0987 126.94 86.4646 120.418C92.6372 129.006 102.868 133.349 112.57 131.486C123.313 129.421 128.721 120.654 129.599 119.182C125.842 111.765 120.515 110.09 120.515 110.09Z"
      fill="#58B18E"
    />
    <path
      d="M60.4401 107.04H59.9766V86.9609H63.0263V104.462C63.0181 105.885 61.8633 107.04 60.4401 107.04Z"
      fill="white"
    />
    <path
      d="M88.3926 151.11C86.7254 151.419 63.8406 155.258 47.665 138.18C34.0186 123.769 35.6289 105.536 35.9135 102.844C35.0677 115.49 34.2138 128.136 33.368 140.79H33.3192L32.7012 150.704L40.2807 151.118L40.2725 151.061C51.5767 151.956 63.2469 152.623 75.2505 153.013C79.6502 153.16 84.0011 153.265 88.3113 153.33C88.3438 152.59 88.3682 151.85 88.3926 151.11Z"
      fill="#50AD88"
    />
    <path
      d="M75.5745 68.5323C76.404 64.279 76.2739 60.9528 76.0299 58.8302C75.851 57.2118 75.5663 55.9919 74.7043 54.829C74.5254 54.585 73.0941 52.7064 70.6462 52.2184C67.7185 51.6329 66.9784 53.7555 62.8146 54.276C59.22 54.7233 58.5125 53.2919 56.0971 54.3166C53.4622 55.4389 52.1528 58.0495 51.7625 58.8383C51.5917 59.188 50.7297 60.9528 50.6402 63.4413C50.5751 65.4094 51.0224 66.8895 51.2013 67.4913C51.9495 70.0368 52.8278 73.9078 53.9095 80.1048C54.4706 80.0804 55.0318 80.0642 55.5929 80.0398C62.2616 76.2012 68.914 72.3708 75.5745 68.5323Z"
      fill="#262626"
    />
    <path
      d="M61.3343 89.4572C58.187 89.4654 55.6334 86.928 55.6172 83.7807L55.5684 71.525L66.962 71.4844L67.0108 83.7401C67.0189 86.8792 64.4816 89.441 61.3343 89.4572Z"
      fill="#F7A99C"
    />
    <path
      d="M66.995 80.3083L59.5781 76.4941C59.6513 77.1366 60.0742 80.1456 62.7173 82.2194C64.4088 83.545 66.1817 83.8459 67.0031 83.9354L66.995 80.3083Z"
      fill="#FF7769"
    />
    <path
      d="M55.2926 67.7282C55.26 67.5412 54.7721 67.2647 54.6176 67.1671C54.3654 67.0044 54.0889 66.8743 53.8043 66.7849C53.2513 66.6059 52.6414 66.5653 52.0639 66.6954C50.9254 66.9556 49.9251 67.9071 49.7299 69.0782C49.6161 69.7532 49.7787 70.4608 50.0715 71.0788C50.5106 72.0141 51.2507 72.8029 52.1453 73.3153C52.8853 73.7463 53.7311 73.9821 54.585 74.0391C54.7558 74.0472 56.5043 74.0065 56.4962 73.9415C56.0977 71.8758 55.6992 69.802 55.2926 67.7282Z"
      fill="#F7A99C"
    />
    <path
      d="M53.7305 67.8507C53.8688 68.7616 54.6658 73.5841 59.0166 76.9998C59.8136 77.626 65.1404 81.798 70.0118 79.7648C76.2738 77.1462 75.5907 66.8667 75.5419 66.3137C75.3955 64.4351 74.9564 58.8887 70.3615 55.9285C66.6124 53.5131 62.5543 54.3995 61.9769 54.5378C61.3669 54.6842 57.504 55.652 55.1374 59.4417C52.9986 62.8736 53.5109 66.3543 53.7305 67.8507Z"
      fill="#F7A99C"
    />
    <path
      d="M71.9967 66.3051C72.0943 67.1102 72.7205 67.6876 73.3792 67.6063C74.0379 67.5249 74.4934 66.8011 74.3958 66.0042C74.2982 65.199 73.6801 64.6216 73.0133 64.7029C72.3464 64.7843 71.891 65.5081 71.9967 66.3051Z"
      fill="#262626"
    />
    <path
      d="M64.7583 67.2152C64.8559 68.0203 65.4739 68.5977 66.1408 68.5164C66.7995 68.4351 67.255 67.7113 67.1574 66.9143C67.0598 66.1092 66.4336 65.5318 65.7748 65.6131C65.1161 65.6944 64.6607 66.4101 64.7583 67.2152Z"
      fill="#262626"
    />
    <path
      d="M69.8085 67.5078L70.9146 69.0042C71.1179 69.2807 71.0447 69.6711 70.7519 69.85L69.7354 70.468"
      stroke="#262626"
      strokeWidth={0.733}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M62.3353 70.9976C62.4492 71.9004 63.2706 72.5347 64.1733 72.4208C65.076 72.307 65.7103 71.4856 65.5965 70.5829C65.4826 69.6802 64.6612 69.0458 63.7585 69.1597C62.864 69.2736 62.2215 70.0949 62.3353 70.9976Z"
      fill="#FF7769"
    />
    <path
      d="M54.5287 71.8437C54.5368 71.6647 54.5531 70.4367 53.569 69.5584C52.7964 68.8671 51.91 68.8184 51.666 68.8184"
      stroke="#262626"
      strokeWidth={0.733}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M53.6661 69.8023C53.6092 69.7942 52.9504 69.7048 52.495 70.2008C52.1209 70.6075 52.1535 71.1117 52.1616 71.2011"
      stroke="#262626"
      strokeWidth={0.733}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M53.5846 66.7268C53.5846 66.7268 54.3978 66.8 55.0078 67.4018C55.6177 68.0036 55.8373 69.0445 55.8373 69.0445C57.3987 68.8575 60.9689 68.1988 64.2056 65.3849C65.9623 63.8641 67.0358 62.2132 67.662 61.0259C68.2556 61.5545 69.2641 62.3515 70.6873 62.9695C72.5496 63.7747 74.8674 64.1569 75.1764 63.5307C75.2333 63.4168 75.1683 63.0834 75.0463 62.4084C74.9975 62.1644 74.9487 61.8066 74.8348 61.465C74.6071 60.79 74.3062 60.1476 73.9484 59.5295C73.2327 58.3015 72.2812 57.2117 71.1589 56.3334C70.427 55.7641 69.63 55.2925 68.7842 54.9265C66.0842 53.7798 62.3352 53.7798 59.1065 55.4958C59.1065 55.4958 55.5038 57.415 53.918 61.1804C53.3731 62.4735 53.2349 63.7665 53.2023 64.2138C53.1373 65.1003 53.2349 65.6777 53.365 66.125C53.4382 66.3852 53.5276 66.5885 53.5846 66.7268Z"
      fill="#262626"
    />
    <path
      d="M74.6962 68.3939C75.0052 68.3532 75.3061 68.4101 75.5745 68.5321C75.5419 69.4104 75.4606 70.4839 75.2654 71.6225C75.2166 71.6306 75.1597 71.655 75.1109 71.655C74.2082 71.7689 73.3868 71.1264 73.273 70.2318C73.1591 69.3372 73.7935 68.5077 74.6962 68.3939Z"
      fill="#FF7769"
    />
    <path
      d="M62.6357 54.7327C62.099 54.5213 61.3182 54.2366 60.3505 53.9601C58.236 53.3502 57.1788 53.0493 56.211 53.1713C53.389 53.5372 51.7707 57.2457 51.0957 58.8071C50.5833 59.9863 49.7375 61.9869 50.1848 64.4917C50.38 65.6059 50.7785 66.4842 51.0875 67.0535"
      stroke="#262626"
      strokeWidth={0.25}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M62.6357 54.3987C63.5872 53.984 64.6119 53.6424 65.5228 53.1707C66.4905 52.6665 67.3282 52.0159 68.361 51.6337C69.4427 51.2271 70.6544 51.0644 71.7604 51.406C72.7119 51.6987 73.5333 52.3331 74.2002 53.065C75.1029 54.0572 75.7941 55.2526 76.1601 56.5457C76.8514 59.0424 76.2333 61.6367 75.8267 64.1252"
      stroke="#262626"
      strokeWidth={0.25}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M71.0363 73.9014C71.0363 74.7146 70.2475 75.3652 69.2716 75.3652C68.2957 75.3652 67.5068 74.7065 67.5068 73.9014C67.5068 73.0962 68.2957 72.4375 69.2716 72.4375C70.2475 72.4375 71.0363 73.0962 71.0363 73.9014Z"
      fill="white"
    />
    <path
      d="M44.8828 61.5874L48.1358 59.6031C48.1358 59.6031 48.8027 56.7404 49.7054 56.1305C50.6081 55.5205 55.4144 53.6582 55.4144 53.6582L56.1138 54.3088C56.1138 54.3088 52.3566 57.7407 51.5759 59.2615C50.7952 60.7823 50.6488 65.0275 50.6488 65.0275L46.9729 69.7768L46.0702 70.46L44.8828 61.5874Z"
      fill="#F7A99C"
    />
    <path
      d="M35.9207 104.674L34.2129 126.656"
      stroke="#262626"
      strokeWidth={0.4147}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M85.6191 106.471L86.9285 130.722"
      stroke="#262626"
      strokeWidth={0.4147}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.4983 71.8438C9.8152 72.3886 6.30195 75.2919 5.73267 80.4317C5.1146 86.0269 8.48146 89.8654 9.01008 90.4428"
      stroke="white"
      strokeWidth={0.4147}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M94.0439 122.02C94.9467 123.028 98.468 126.688 104.372 127.485C109.976 128.241 114.156 125.939 115.359 125.216"
      stroke="white"
      strokeWidth={0.4147}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M247.684 141.277L255.222 137.243L266.413 139.293L272.008 133.323L285.248 136.869L292.616 126.801L305.766 134.861L301.293 106.429L283.938 94.2793L244.048 100.558C242.552 100.793 241.535 102.192 241.771 103.689L247.684 141.277Z"
      fill="#F5F5F5"
    />
    <path
      d="M287.989 108.527L301.294 106.437L283.939 94.2871L285.948 107.047C286.103 108.015 287.014 108.682 287.989 108.527Z"
      fill="#D6D6D6"
    />
    <path
      d="M283.939 94.2793L285.948 107.039C286.103 108.015 287.022 108.682 287.998 108.527L301.302 106.437"
      stroke="#262626"
      strokeWidth={0.274}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M253.06 178.613C253.296 180.109 254.695 181.126 256.191 180.89L308.451 172.668C309.947 172.432 310.964 171.033 310.728 169.537L305.767 138.04L292.617 129.98L285.249 140.049L272.009 136.503L266.414 142.472L255.223 140.423L247.685 144.456L253.06 178.613Z"
      fill="#F5F5F5"
    />
    <path
      d="M247.691 141.277L255.23 137.243L266.421 139.293L272.016 133.323L285.256 136.869L292.624 126.801L305.774 134.861L301.301 106.429L283.946 94.2793L244.056 100.558C242.56 100.793 241.543 102.192 241.779 103.689L247.691 141.277Z"
      stroke="#262626"
      strokeWidth={0.274}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M253.068 178.613C253.304 180.109 254.703 181.126 256.199 180.89L308.459 172.668C309.955 172.432 310.971 171.033 310.736 169.537L305.775 138.04L292.625 129.98L285.256 140.049L272.017 136.503L266.422 142.472L255.231 140.423L247.692 144.456L253.068 178.613Z"
      stroke="#262626"
      strokeWidth={0.274}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M45.5257 52.8447L44.2082 54.0971L32.5381 43.5655L35.8968 40.3613L45.5257 52.8447Z"
      fill="#FDB6D6"
    />
    <path
      d="M48.4127 50.6808L46.6155 51.0142L42.6143 35.8145L47.1766 34.9688L48.4127 50.6808Z"
      fill="#FDB6D6"
    />
    <path
      d="M42.1917 55.707L42.0453 57.5287L26.3252 57.4067L26.6993 52.7793L42.1917 55.707Z"
      fill="#FDB6D6"
    />
    <path
      d="M230.012 286.214C226.068 282.335 226.011 275.999 229.89 272.055L275.993 225.139L290.274 239.175L244.17 286.092C240.299 290.036 233.956 290.093 230.012 286.214Z"
      fill="#305446"
    />
    <path
      opacity={0.2}
      d="M312.267 243.105C334.63 243.054 352.718 224.884 352.667 202.521C352.616 180.158 334.447 162.07 312.084 162.121C289.721 162.172 271.633 180.342 271.684 202.705C271.734 225.068 289.904 243.155 312.267 243.105Z"
      fill="#DCF0FF"
    />
    <path
      opacity={0.5}
      d="M274.285 216.885C275.651 220.52 277.554 223.993 279.994 227.181L352.658 202.051C352.601 198.123 351.975 194.203 350.788 190.422L274.285 216.885Z"
      fill="white"
    />
    <path
      opacity={0.5}
      d="M271.78 205.288C271.935 207.581 272.276 209.867 272.821 212.119L348.998 185.77C348.047 183.696 346.917 181.679 345.599 179.752L271.78 205.288Z"
      fill="white"
    />
    <path
      d="M344.932 169.277C326.528 151.19 296.934 151.442 278.847 169.854C260.761 188.258 261.013 217.853 279.425 235.939C297.829 254.026 327.423 253.774 345.51 235.362C363.596 216.958 363.336 187.372 344.932 169.277ZM285.199 230.059C270.04 215.161 269.828 190.796 284.727 175.637C299.626 160.478 323.991 160.266 339.15 175.165C354.309 190.064 354.52 214.429 339.622 229.588C324.723 244.747 300.358 244.958 285.199 230.059Z"
      fill="#305446"
    />
    <path
      d="M279.23 243.494L242.771 280.587"
      stroke="white"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M315.525 159.233C304.042 158.078 292.128 162.038 283.63 169.854C275.131 177.669 270.178 189.201 270.365 200.741"
      stroke="white"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default NoPage;
