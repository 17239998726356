// components/ErrorBoundary.js
import React from 'react';
import { useRouter } from 'next/router';
import NoPage from './icons/NoPage';

const ErrorBoundaryWrapper = ({ children }) => {
  const { locale, push } = useRouter();

  return (
    <ErrorBoundary locale={locale} push={push}>
      {children}
    </ErrorBoundary>
  );
};

export default ErrorBoundaryWrapper;

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error('Error Boundary caught an error:', error, errorInfo);
  }

  render() {
    const { locale } = this.props;

    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className='my-5 flex min-h-[70vh] flex-col items-center justify-center gap-2 sm:my-10'>
          <NoPage className='h-[150px] w-[190px] sm:h-56 sm:w-60' />
          <p className='text-xl font-extrabold'>
            {locale === 'en' ? 'Something went wrong' : 'حدث خطأ'}
          </p>
          <p className='mt-2 w-80 text-center text-zinc-500 max-sm:text-[15px] sm:max-w-96'>
            {locale === 'en'
              ? 'An unexpected error occurred. Please try again later or contact support if the problem persists.'
              : 'حدث خطأ غير متوقع. الرجاء المحاولة مرة أخرى لاحقًا أو الاتصال بالدعم إذا استمرت المشكلة.'}
          </p>
          <div className='flex gap-2'>
            <button
              onClick={() => window.location.reload()}
              className='mt-3 rounded-md bg-primary px-4 py-2 text-white'
            >
              {locale === 'en' ? 'Reload Page' : 'تحديث الصفحة'}
            </button>
            <button
              onClick={() => (window.location.href = '/')}
              className='mt-3 rounded-md bg-primary px-4 py-2 text-white'
            >
              {locale === 'en'
                ? 'Return To Home Page'
                : 'العودة للصفحة الرئيسية'}
            </button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
